import React from 'react';
import arcRules1 from '../assets/img/arc-rules-1.png';
import arcRules2 from '../assets/img/arc-rules-2.png';
import arcRules3 from '../assets/img/arc-rules-3.png';
import arcRules4 from '../assets/img/arc-rules-4.png';
import SvgGuestRulesGRDogLeash from 'src/assets/svg/SvgGuestRulesGRDogLeash.svg';
import SvgGuestRulesGRDogPoopClean from 'src/assets/svg/SvgGuestRulesGRDogPoopClean.svg';
import SvgGuestRulesGRShieldCheck from 'src/assets/svg/SvgGuestRulesGRShieldCheck.svg';
import SvgGuestRulesGRTaskListPin from 'src/assets/svg/SvgGuestRulesGRTaskListPin.svg';
import SvgGuestRulesGRTimeClock from 'src/assets/svg/SvgGuestRulesGRTimeClock.svg';
import SvgGuestRulesGRToiletSign from 'src/assets/svg/SvgGuestRulesGRToiletSign.svg';

export const HAS_REVIEWS = {
    NO: 'NO',
    HOST: 'HOST',
    GUEST: 'GUEST',
    BOTH: 'BOTH',
};

export const USER_TEST_GROUP = {
    SEARCH_ALGO_2024: 'backSearchAlgo2024',
    BOOK_CAL: 'frontBookingCalendar',
    GODD_SPOT: 'frontGoodSpot',
    SHORT_ADD_SPOT: 'frontShortSpotFlow',
    MOBILE_ADOPTION: 'frontMobileAdoption',
    ONLY_BIG_SPOTS: 'frontOnlyBigSpots',
    SEARCH_FILTERS: 'frontSearchFilters',
    SEO_CITY: 'frontSeoCityPage',
    SUPER_SHORT_ADD_SPOT: 'frontSuperShortAddSpot',
    HOME_PAGE: 'frontHomePhoto',
    JUNE_SHORT_ADD_SPOT: 'frontShortSpot0622',
    HOST_POST_PUBLISH: 'frontHostPostPublish',
    HOST_ADD_SPOT_PHOTOS: 'frontAddSpotReqPhotos',
    HOME_SEARCH: 'frontHomeSearchNew',
    HOST_EARNINGS: 'frontHostEarnings',
    HOST_TEN_PHOTOS_SPOT: 'frontSpotTenPhotos',
    QUICK_BOOK_BTN_SEARCH: 'frontSearchPage',
    HOME_PAGE_V2: 'frontHomePageV2',
    LISTING_AA_TEST: 'frontTest',
    HOST_LAND_ADD_SPOT: 'frontHostLandingAddSpot',
    POST_PUB_ADD_SPOT: 'frontPostPublish',
    HOME_SEARCH_V3: 'homeSearchV3',
    PHOTO_WARNING_ALERT: 'frontPhotoWarningAlert',
    HOST_SPOT_FLOW_PHOTO_COPY: 'hostSpotFlowPhotoCopy',
    HOST_SPOT_FLOW_PHOTO_COPY2: 'hostSpotFlowPhotoCopy2',
    HOST_ADD_SPOT_FLOW: 'frontHostSignupFlow',
    ONE_PAGE_CHECKOUT: 'frontOnePageCheckout',
    SPOT_DETAIL_TOP_BAR: 'frontSpotDetailTopBar',
    GUEST_SIGNUP_PHONE_TEST: 'frontGuestSignupPhone',
    SNIFF_TOP_BAR_SIGNUP_5019: 'frontTopBarSignup',
    GUEST_AUTH_TEST: 'frontAuthForSpotContent',
    SNIFF_SEO_POPUP_4936: 'frontSeoPopup4936',
    SNIFF_FILTER_AUTH_4954: 'frontFilterListingsAuth',
    GUEST_EMAIL_ONLY_SIGNUP: 'frontEmailOnlySignup',
    APP_DOWNLOAD_PROMPT_TEST: 'frontAppDownloadPrompt',
    MULTI_PAY_METHODS_TEST: 'frontMultiPayMethod',
    GUEST_HERO_SIGNUP: 'frontGuestHeroSignup',
    HOST_SPOT_DESCRIPTION: 'frontHostSpotDescription',
    HOST_SPOT_INSTRUCTIONS: 'frontHostSpotInstruction',
    HOST_SPOT_DISTRACTIONS: 'frontHostSpotDistraction',
    GUEST_PHOTO_CAROUSEL: 'frontPhotoCarousel',
    HOST_SPOT_PHOTO_COPY_V3: 'frontHostSpotPhotoCopyV3',
    APP_REQUIRED_SNIFFPASS: 'appRequiredSniffpass',
    GLOBAL_REQUIRED_SNIFFPASS: 'globalRequiredSniffpass',
    GUEST_SPOT_DETAIL_SCROLL: 'frontSpotDetailScroll',
    SNIFF_FILTERS_BTN_LOC: 'frontFiltersBtnLoc',
};

export const SIGN_UP_SRC = {
    AD_POPUP_WEB: 'AD_POPUP_WEB', // Ad popup
    BOTTOM_SEARCH_WEB: 'BOTTOM_SEARCH_WEB', // Bottom search
    CALENDAR_WEB: 'CALENDAR_WEB', // Calendar
    CHECKOUT_WEB: 'CHECKOUT_WEB', // Checkout
    FAVORITE_SEARCH_WEB: 'FAVORITE_SEARCH_WEB', // Favorite search
    FAVORITE_SPOT_WEB: 'FAVORITE_SPOT_WEB', // Favorite spot
    HOST_BANNER_WEB: 'HOST_BANNER_WEB', // Host banner
    HOST_CTA_WEB: 'HOST_CTA_WEB', // Host CTA
    HOST_LANDING_SHORT_WEB: 'HOST_LANDING_SHORT_WEB',
    INVITE_WEB: 'INVITE_WEB', // Invite
    MEMBERSHIPS_WEB: 'MEMBERSHIPS_WEB',
    MESSAGE_HOST_WEB: 'MESSAGE_HOST_WEB', // Message host
    NAV_WEB: 'NAVMENU_WEB', // Nav menu
    NEWHEADER_WEB: 'NEWHEADER_WEB', // New mobile top bar
    PHOTO_REQUEST_WEB: 'PHOTO_REQUEST_WEB', // Photo request
    REPORT_WEB: 'REPORT_WEB', // Report
    REVIEW_WEB: 'REVIEW_WEB',
    SEARCHPOPUP_WEB: 'SEARCHPOPUP_WEB',
    SEOPOPUP_WEB: 'SEOPOPUP_WEB',
    SNIFFPASS_WEB: 'SNIFFPASS_WEB',
    SPOT_DETAILS_WEB: 'SPOT_DETAILS_WEB',
    HOME_FORM_WEB: 'HOME_WEB', //TODO: Add 'home_form_web' to backend
    FILTERS_WEB: 'FILTERS_WEB',
    PHOTO_CAROUSEL_WEB: 'PHOTO_CAROUSEL_WEB',
    SPOT_DETAIL_SCROLL_WEB: 'SPOT_DETAIL_SCROLL_WEB',
    ADD_PEOPLE_WEB: 'ADD_PEOPLE_WEB',
};

export const GUEST_RULES_LIST = [
    {
        icon: <SvgGuestRulesGRDogLeash />,
        text: 'Dogs must be leashed when entering and exiting the spot.',
    },
    {
        icon: <SvgGuestRulesGRShieldCheck />,
        text: 'Make sure the area is safe before bringing your dog in.',
    },
    {
        icon: <SvgGuestRulesGRTimeClock />,
        text: 'Never enter a spot before your booking start time and never leave late.',
    },
    {
        icon: <SvgGuestRulesGRDogPoopClean />,
        text: 'Pick up after your dogs. Leave the spot as it was when you arrived.',
    },
    {
        icon: <SvgGuestRulesGRTaskListPin />,
        text: 'Always review and abide by spot specific rules.',
    },
    {
        icon: <SvgGuestRulesGRToiletSign />,
        text: 'Unless otherwise stated, restrooms are not available on site.',
    },
];

export const HOST_RULES_LIST = [
    {
        icon: arcRules1,
        text: 'Never meet guest dogs unless you confirm with the guest in advance.',
    },
    {
        icon: arcRules2,
        text: 'If you have dogs, make sure they are kept securely away from the spot.',
    },
    {
        icon: arcRules3,
        text: 'Try to keep your spot reasonably clean and free of hazards.',
    },
    {
        icon: arcRules4,
        text: 'Make sure any gates are unlocked and guests can easily access your spot.',
    },
];
